var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('headTitle'),_c('img',{staticClass:"mobile-example-headImg",attrs:{"src":require("../../assets/image/mobile/mobile-product-case-banner-metallurgy.png")}}),_vm._m(0),(_vm.hasDescription)?_c('div',{staticClass:"description"},[_c('div',{staticClass:"title1"},[_vm._v("项目介绍")]),(!_vm.showDescription)?_c('div',{staticClass:"title2",on:{"click":function($event){(_vm.showDescription = !_vm.showDescription)}}},[_vm._v("展开"),_c('i',{staticClass:"el-icon-arrow-down"})]):_vm._e(),(_vm.showDescription)?_c('div',{staticClass:"title2",on:{"click":function($event){(_vm.showDescription = !_vm.showDescription)}}},[_vm._v("收起"),_c('i',{staticClass:"el-icon-arrow-up"})]):_vm._e()]):_vm._e(),(_vm.hasDescription)?_c('div',{staticStyle:{"margin-top":"2rem","padding-bottom":"5.7rem"}},[_c('el-collapse-transition',[(_vm.showDescription)?_c('div',[_c('div',{staticClass:"description-box"})]):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"mobile-head-catalogue"},[_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==1},on:{"click":function($event){(_vm.checkIndex=1)}}},[_c('span',[_vm._v("业务痛点")])]),_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==2},on:{"click":function($event){(_vm.checkIndex=2)}}},[_c('span',[_vm._v("实施方案")])]),_c('div',{class:{'title1':true,'ischecked':_vm.checkIndex==3},on:{"click":function($event){(_vm.checkIndex=3)}}},[_c('span',[_vm._v("实施收益")])])]),((_vm.checkIndex==1))?_c('div',{staticClass:"mobile-content-box1"},[_vm._m(1),_vm._m(2),_vm._m(3)]):((_vm.checkIndex==2))?_c('div',{staticClass:"mobile-content-box1"},[_vm._m(4)]):((_vm.checkIndex==3))?_c('div',{staticClass:"mobile-content-box1",staticStyle:{"margin-bottom":"5rem"}},[_vm._m(5)]):_vm._e(),_c('mobileFoot')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-head-explain"},[_c('div',{staticClass:"mobile-head-explain-title"},[_vm._v("IT 服务门户")]),_c('div',{staticClass:"mobile-head-explain-content"},[_vm._v("某大型国有冶金集团")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" IT问题处理流程复杂 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 集团拥有数十家分布在全国各地的分子公司，每个公司都有自己独立的业务模式和部门结构，IT问题的处理需要经过多个层级，导致信息传递效率低下，甚至可能出现信息失真。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 运维工作任务繁重 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 拥有上万员工，每天都有大量的IT问题产生，响应速度要求高，在某些情况下，由于问题较为复杂，运维需要花费大量时间了解和澄清问题。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ware-box1"},[_c('div',{staticClass:"title1"},[_vm._v(" 未能建立知识库 ")]),_c('div',{staticClass:"ware-box1-content"},[_vm._v(" 没有建立信息部门的知识库，过度依赖特定的IT人员，影响故障解决速度。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchase-box"},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-metallurgy-1.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("未能建立知识库")]),_c('div',{staticClass:"title2"},[_vm._v("根据实际业务需求，进一步细化工作空间的层级结构，并设定清晰的角色定义，明确每个角色在工作空间中的职责和权限。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-metallurgy-2.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("智能派单&关键用户")]),_c('div',{staticClass:"title2"},[_vm._v("智能派单自动匹配关键用户，他们完成咨询和初筛问题，不仅有效提升了问题解决的速度，还极大地降低了运维团队的工作压力和响应时间。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-metallurgy-3.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("AI知识库")]),_c('div',{staticClass:"title2"},[_vm._v("借助 Vticket 知识库功能，将运维相关的文档及所涉及的流程、材料、检查项等总结归纳为标准和要求，并且生成知识。")])])]),_c('div',{staticClass:"card"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/image/productCase/icon-metallurgy-4.svg")}}),_c('div',[_c('div',{staticClass:"title1"},[_vm._v("AI客服")]),_c('div',{staticClass:"title2"},[_vm._v("AI客服可以实现高效的员工自助服务，从而提高企业的整体能效。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-bottom":"10rem"}},[_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("问题的平均响应和解决时间，分别减少了 46% 和 35% 。")]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("运维工程师的无效沟通时间极大减少。")]),_c('div',{staticClass:"purchase-title",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"purchase-doit"},[_vm._v("●")]),_vm._v("服务过程中的信息和操作，完整记录下来，且数据安全且可靠。")])])
}]

export { render, staticRenderFns }