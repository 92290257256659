<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/mobile-product-case-banner-metallurgy.png"/>
        <div class="mobile-head-explain">
          <div class="mobile-head-explain-title">IT 服务门户</div>
          <div class="mobile-head-explain-content">某大型国有冶金集团</div>
        </div>
        <div class="description" v-if="hasDescription">
            <div class="title1">项目介绍</div>
            <div v-if="!showDescription" class="title2" @click="(showDescription = !showDescription)">展开<i class="el-icon-arrow-down"></i></div>
            <div v-if="showDescription" class="title2" @click="(showDescription = !showDescription)">收起<i class="el-icon-arrow-up"></i></div>
        </div>
        <div style="margin-top: 2rem;padding-bottom: 5.7rem;" v-if="hasDescription">
          <el-collapse-transition>
            <div v-if="showDescription">
              <div class="description-box"></div>
            </div>
          </el-collapse-transition>
        </div>
        <div class="mobile-head-catalogue">
          <div :class="{'title1':true,'ischecked':checkIndex==1} " @click="(checkIndex=1)"><span>业务痛点</span></div>
          <div :class="{'title1':true,'ischecked':checkIndex==2} " @click="(checkIndex=2)"><span>实施方案</span></div>
          <div :class="{'title1':true,'ischecked':checkIndex==3} " @click="(checkIndex=3)"><span>实施收益</span></div>
        </div>
        <div v-if="(checkIndex==1)" class="mobile-content-box1">
          <div class="ware-box1">
            <div class="title1">
              IT问题处理流程复杂
            </div>
            <div class="ware-box1-content">
              集团拥有数十家分布在全国各地的分子公司，每个公司都有自己独立的业务模式和部门结构，IT问题的处理需要经过多个层级，导致信息传递效率低下，甚至可能出现信息失真。
            </div>
          </div>
          <div class="ware-box1">
            <div class="title1">
              运维工作任务繁重
            </div>
            <div class="ware-box1-content">
              拥有上万员工，每天都有大量的IT问题产生，响应速度要求高，在某些情况下，由于问题较为复杂，运维需要花费大量时间了解和澄清问题。
            </div>
          </div>
          <div class="ware-box1">
            <div class="title1">
              未能建立知识库
            </div>
            <div class="ware-box1-content">
              没有建立信息部门的知识库，过度依赖特定的IT人员，影响故障解决速度。
            </div>
          </div>
        </div>
        <div v-else-if="(checkIndex==2)" class="mobile-content-box1">
          <div class="purchase-box">
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-metallurgy-1.svg"/>
              <div>
                <div class="title1">未能建立知识库</div>
                <div class="title2">根据实际业务需求，进一步细化工作空间的层级结构，并设定清晰的角色定义，明确每个角色在工作空间中的职责和权限。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-metallurgy-2.svg"/>
              <div>
                <div class="title1">智能派单&关键用户</div>
                <div class="title2">智能派单自动匹配关键用户，他们完成咨询和初筛问题，不仅有效提升了问题解决的速度，还极大地降低了运维团队的工作压力和响应时间。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-metallurgy-3.svg"/>
              <div>
                <div class="title1">AI知识库</div>
                <div class="title2">借助 Vticket 知识库功能，将运维相关的文档及所涉及的流程、材料、检查项等总结归纳为标准和要求，并且生成知识。</div>
              </div>
            </div>
            <div class="card">
              <img class="logo" src="../../assets/image/productCase/icon-metallurgy-4.svg"/>
              <div>
                <div class="title1">AI客服</div>
                <div class="title2">AI客服可以实现高效的员工自助服务，从而提高企业的整体能效。</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="(checkIndex==3)" class="mobile-content-box1" style="margin-bottom:5rem">
          <div style="margin-bottom:10rem">
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>问题的平均响应和解决时间，分别减少了 46% 和 35% 。</div>
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>运维工程师的无效沟通时间极大减少。</div>
            <div class="purchase-title" style="display:flex"><div class="purchase-doit">●</div>服务过程中的信息和操作，完整记录下来，且数据安全且可靠。</div>
          </div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileProdutCaseMetallurgy',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            checkIndex: 1,
            showDescription:true,
            hasDescription: false,
        };
    },

    mounted() {
    },

    methods: {
    },
};
</script>

<style lang="less" scoped>
.description{
    display: flex;
    width: 66.5rem;
    margin: auto;
    margin-top: 6rem;
    .title1{
        font-size: 3.3rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #11A84F;
        line-height: 4.2rem;
    }
    .title2{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 4.2rem;
        margin-left: 65%;
    }
}
.description-box{
    max-width: 66.5rem;
    margin: auto;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 5rem;
}
.mobile-head-catalogue{
    width: 100%;
    height: 9rem;
    display: flex;
    background: #F8F8F8;
    .title1{
        width: 20rem;
        height: 9rem;
        display: flex;
        text-align: center;
        align-items: center;
        span{
            font-size: 2.8rem;
            font-family: 'CN_Medium';
            font-weight: 500;
            color: #4F4F4F;
            line-height: 2rem;
            margin: auto;
        }
    }
    .ischecked{
        background: #EDEDED;
        border-bottom: 2px solid #11A84F;
    }
}
.mobile-content-box1{
    width: 66rem;
    margin: auto;
    margin-top: 3rem;
    padding-bottom: 3rem;
    .title2{
        // position: absolute;
        // width: 4rem;
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #005395;
        line-height: 2.8rem;
        margin: 1.9rem 0 0 3rem;
        cursor: pointer;
        .line{
            width: 6rem;
            height: 2rem;
            border-bottom: 3px solid #005395;
            border-radius: 50%;
            margin: -1rem 0 0 0rem;
        }
    }
}
.ware-content1{
    max-width: 66rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;
}
.ware-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;
}
.ware-box1{
    width: 66.8rem;
    min-height: 10rem;
    padding: 3rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    flex-direction: column;
    text-align: justify;
    .title1{
        height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem;
    }
    .ware-box1-content {
      margin-top: 1.2rem;
      font-size: 2.2rem;
      font-family: 'CN_Regular';
      font-weight: 400;
      color: #999999;
      line-height: 3rem;
    }
}
.ware-box2{
    width: 66.8rem;
    height: 20rem;
    border: 1px solid #CCCCCC;
    margin: auto;
    margin-top: 1.3rem;
    display: flex;
    .title1{
        width: 58.8rem;
        // height: 4rem;
        font-size: 2.8rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #666666;
        line-height: 4rem;
        margin: auto;
        align-items: center;
    }
}
.ware-img{
    width: 66.5rem;
    height: 43.5rem;
}
.ware-img2{
    width: 66.5rem;
    height: 28rem;
}
.purchase-box{
    width: 100%;
    // height: 26.5rem;
    /*设置当前元素为flex模式*/
    display: flex;
    /*行元素默认不折行，设置为折行*/
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;

    .card{
        width: 52.8rem;
        min-height: 30rem;
        background: #FFFFFF;
        box-shadow: 0px 0px 12px 0px rgba(219,219,219,0.5);
        border-radius: 2px;
        // justify-content: center;
        margin: auto;
        margin-top: 2rem;
        display: flex;
        .logo{
            width: 4rem;
            height: 4rem;
            margin: 3rem 0 0 2rem;
        }
        .title1{
            height: 2rem;
            width: 100%;
            font-size: 2.8rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #333333;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
        .title2{
            width: 43.2rem;
            height: 7.2rem;
            font-size: 2.5rem;
            font-family: 'CN_Regular';
            font-weight: 400;
            color: #666666;
            line-height: 4rem;

            margin: 2rem 0 0 2.1rem;
        }
    }
}
.purchase-title{
    font-size: 2.8rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 4.2rem;

    margin-top: 2rem;
}
.purchase-doit{
    color: #005395;
    font-size: 1rem;
    transform: scale(0.8);
    margin-right: 1.4rem;
}
.purchase-content{
    max-width: 66.5rem;
    // height: 10.6rem;
    font-size: 2.8rem;
    font-family: 'CN_Normal';
    font-weight: 400;
    color: #333333;
    line-height: 4rem;

    margin: 1rem 0 0 3.5rem;
}
</style>
